

/* Классы используемые для изменения одного или малого количества свойств элементов */
// Атомарные стили начинаются с двух подчеркиваний

.A-
    &debag
        &_top
            position: relative;
            top: -1px;
        &_bottom
            position: relative;
            bottom: -1px;

    /*---------  overflow  ----------*/

    &ov
      &_hidden
        overflow: hidden
      &_auto
        overflow: auto
      &_visible
        overflow: visible

    /*---------  TRBL  ----------*/

    &resetOffsets
        top: 0
        right: 0
        bottom: 0
        left: 0

    /*----------  height  -----------*/

    &mh
      &_fh
        max-height: 100%
        min-height: 100%

    /*-----------  color  -------------*/
    &color
        &_yellow
            background: #fff176

    /*-----------  background  -------------*/
    &bg
       &_none
        background: transparent

    /*-----------  flex  -------------*/

    // TODO: Separate diplay flex and other properties
    &flex
      display: flex
      &_column
        display: flex
        flex-direction: column
      &_row
        display: flex
        flex-direction: row
      &_1
        display: flex
        flex: 1
      &_0
        display: flex
        flex: 0
      &_auto
        display: flex
        flex: 0 0 auto
      &_alignSelf
          &_stretch
              display: flex
              align-self: stretch
          &_center
              display: flex
              align-self: center
          &_start
              display: flex
              align-self: flex-start
          &_end
              display: flex
              align-self: flex-end
      &_alignItems
          &_stretch
              display: flex
              align-items: stretch
          &_baseline
              display: flex
              align-items: baseline
          &_start
              display: flex
              align-items: flex-start
          &_end
              display: flex
              align-items: flex-end
          &_space_between
              display: flex
              align-items: space-between
          &_center
            display: flex;
            align-items: center;
            justify-content: center;
      &_justifyContent
          &_spaceBetween
              display: flex
              justify-content: space-between


    /*----------  display  ----------*/
    &disp
        &_inlineBlock
            display: inline-block
        &_block
            display: block
        &_none
            display: none
        &_flex
          display: flex
          flex: 1

    /*----------  position  ----------*/
    &pos
        &_relative
            position: relative
        &_absolute
            position: absolute
        &_left
            float: left
        &_right
            float: right
        &_middle
            vertical-align: middle
        &_top
            vertical-align: top
        &_bottom
            vertical-align: bottom
        &_center
            margin-left: auto
            margin-right: auto
        &_none
            float: none !important
            position: static !important
            vertical-align: top !important
        &_noFloat
            float: none !important

    /*----------  text  ----------*/
    &text
        &_left
            text-align: left !important
        &_center
            text-align: center
            h1
            h2
            h3
            h4
            h5
                text-align: center
        &_right
            text-align: right !important
        &_uppercase
            text-transform: uppercase
        &_noUppercase
            text-transform: none
        &_lineHigh
            line-height: 1.4em
        &_noWrap
            white-space: nowrap;

    /*----------  font  ----------*/
    &font
        &_xxl
            font-weight: 900
            letter-spacing:-1px
            font-size: 20px
            color: black
        &_xl
        &_lg
        &_md
            font-size: 18px
            line-height: 1.4em
        &_hide
            font-size: 0
            line-height: 0
        &_bold
            font-weight: 700 !important
        &_noBold
            font-weight: normal !important


    /*----------  fields  ----------*/
    &mg
        &_no
            margin: 0 !important
        &Top
            &_xl
                margin-top: 70px !important
            &_lg
                margin-top: 50px !important
            &_md
                margin-top: 29px !important
            &_sm
                margin-top: 20px !important
            &_xs
                margin-top: 15px !important
            &_no
                margin-top: 0 !important
            &_5
                margin-top: 5px!important
            &_10
                margin-top: 10px!important
            &_40
                margin-top: 40px!important
        &Right
            &_lg
                margin-right: 66px !important
            &_md
                margin-right: 25px !important
            &_sm
                margin-right: 13px !important
            &_xs
                margin-right: 7px !important
            &_20
                margin-right: 20px !important
            &_15
                margin-right: 15px !important
            &_10
                margin-right: 10px !important
            &_no
                margin-right: 0!important
        &Left
            &_lg
                margin-left: 66px !important
            &_md
                margin-left: 25px !important
            &_sm
                margin-left: 13px !important
            &_xs
                margin-left: 7px !important
            &_20
                margin-left: 20px !important
            &_15
                margin-left: 15px !important
            &_10
                margin-left: 10px !important
            &_no
                margin-left: 0!important
        &Bottom
            &_xs
                margin-bottom: 7px !important
            &_sm
                margin-bottom: 13px !important
            &_md
                margin-bottom: 28px !important
            &_lg
                margin-bottom: 40px !important
            &_no
                margin-bottom: 0 !important
            &_20
                margin-bottom: 20px!important
            &_10
                margin-bottom: 10px!important
    &pd
        &_no
            padding: 0 !important
            &Top
                padding-top: 0 !important
        &Top
            &_xl
                padding-top: 70px !important
            &_lg
                padding-top: 50px !important
            &_md
                padding-top: 44px !important
            &_sm
                padding-top: 20px !important
            &_xs
                padding-top: 10px !important
            &_15
                padding-top: 15px !important
        &Right
            &_lg
                padding-right: 66px !important
            &_md
                padding-right: 25px !important
            &_sm
                padding-right: 13px !important
            &_smaller
                padding-right: 10px !important
            &_xs
                padding-right: 7px !important
            &_no
                padding-right: 0 !important
            &_20
                padding-right: 20px !important
        &Left
            &_xs
                padding-left: 7px !important
            &_smaller
                padding-left: 10px !important
            &_sm
                padding-left: 13px !important
            &_md
                padding-left: 25px !important
            &_no
                padding-left: 0 !important
            &_20
                padding-left: 20px !important
        &Bottom
            &_xs
                padding-bottom: 7px !important
            &_smaller
                padding-bottom: 10px !important
            &_sm
                padding-bottom: 13px !important
            &_md
                padding-bottom: 28px !important
            &_no
                padding-bottom: 0 !important
    &clear
        clear:both

/*----------  Modificator  ----------*/
._, .A-
    &noAfter
        &:after
            display: none !important
    &noBefore
        &:before
            display: none !important
    &noResize
        resize: none
    &noSelectable
        user-select: none
    &overlay
        position: absolute
        left: 0
        top: 0
        width: 100%
        height: 100%
    &circle
        border-radius: 1000px
    &border
    &borderFull
        padding-bottom: 10px
        min-width: 100%
        + ._border
        + ._borderFull
            margin-top: 10px
    &link
      &_noStyle
        border: 0 !important
        color: initial
      &_dotted
        border-bottom: 1px dotted

.A-mobile-disp_block
    display: none
/*---------------- Мобилка ----------------*/
@media screen and (max-width: 1000px)
    .A-
        &mobile
            &-pd
                &Left
                    &_20
                        padding-left: 20px
            &-mg
                &Top
                    &_20
                        margin-top: 20px
                &Bottom
                    &_20
                        margin-bottom: 20px
            &-disp_none
                display: none            
            &-disp_block
                display: block
            &-disp_inline_block
                display: inline-block
            &-whiteSpaceNo
                white-space: nowrap
                overflow: hidden
            &-float_none
                float: unset
            &-text_center
                text-align: center
            &-font
                &_24
                    font-size: 24px!important
                &_18
                    font-size: 18px!important