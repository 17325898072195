i.cat_icon
i.love_icon
i.play_icon
i.play_video_min
i.play_video_full
i.hd
  display: inline-block

i.cat_icon
  background url("images/icons/cat_icon.png")
  width: 22px
  height: 22px
i.love_icon
  background url("images/icons/love_icon.png")
  width: 25px
  height: 22px
i.play_icon
  background url("images/icons/play_icon.png")
  width: 19px
  height: 22px
i.play_video_min
  background url("images/icons/video_play_icon.png") no-repeat center center
  width: 100%
  height: 100%
i.play_video_full
  background url("images/icons/video_play_icon_big.png") no-repeat center center
  width: 100%
  height: 100%
i.play_video_min
i.play_video_full
  transition all .3s
  cursor pointer
  &:hover
    background-color rgba(0,0,0,.2)
i.hd
  background url("images/icons/hd.png") no-repeat
  width: 36px
  height: 25px