@charset "UTF-8"
@import 'stylus/atomic.styl'
@import 'icons.styl'
@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('src/fonts/HelveticaNeueCyr-Light.eot');
  src: url('src/fonts/HelveticaNeueCyr-Light.eot?#iefix') format('embedded-opentype'),
          url('src/fonts/HelveticaNeueCyr-Light.woff2') format('woff2'),
          url('src/fonts/HelveticaNeueCyr-Light.woff') format('woff'),
          url('src/fonts/HelveticaNeueCyr-Light.ttf') format('truetype'),
          url('src/fonts/HelveticaNeueCyr-Light.svg#HelveticaNeueCyr-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('src/fonts/HelveticaNeueCyr-Thin.eot');
  src: url('src/fonts/HelveticaNeueCyr-Thin.eot?#iefix') format('embedded-opentype'),
          url('src/fonts/HelveticaNeueCyr-Thin.woff2') format('woff2'),
          url('src/fonts/HelveticaNeueCyr-Thin.woff') format('woff'),
          url('src/fonts/HelveticaNeueCyr-Thin.ttf') format('truetype'),
          url('src/fonts/HelveticaNeueCyr-Thin.svg#HelveticaNeueCyr-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('src/fonts/HelveticaNeueCyr-Bold.eot');
  src: url('src/fonts/HelveticaNeueCyr-Bold.eot?#iefix') format('embedded-opentype'),
          url('src/fonts/HelveticaNeueCyr-Bold.woff2') format('woff2'),
          url('src/fonts/HelveticaNeueCyr-Bold.woff') format('woff'),
          url('src/fonts/HelveticaNeueCyr-Bold.ttf') format('truetype'),
          url('src/fonts/HelveticaNeueCyr-Bold.svg#HelveticaNeueCyr-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('src/fonts/HelveticaNeueCyr-Medium.eot');
  src: url('src/fonts/HelveticaNeueCyr-Medium.eot?#iefix') format('embedded-opentype'),
          url('src/fonts/HelveticaNeueCyr-Medium.woff2') format('woff2'),
          url('src/fonts/HelveticaNeueCyr-Medium.woff') format('woff'),
          url('src/fonts/HelveticaNeueCyr-Medium.ttf') format('truetype'),
          url('src/fonts/HelveticaNeueCyr-Medium.svg#HelveticaNeueCyr-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('src/fonts/HelveticaNeueCyr-Roman.eot');
  src: url('src/fonts/HelveticaNeueCyr-Roman.eot?#iefix') format('embedded-opentype'),
          url('src/fonts/HelveticaNeueCyr-Roman.woff2') format('woff2'),
          url('src/fonts/HelveticaNeueCyr-Roman.woff') format('woff'),
          url('src/fonts/HelveticaNeueCyr-Roman.ttf') format('truetype'),
          url('src/fonts/HelveticaNeueCyr-Roman.svg#HelveticaNeueCyr-Roman') format('svg');
  font-weight: normal;
  font-style: normal;
}

html
  box-sizing border-box
  -ms-text-size-adjust 100%
  -webkit-text-size-adjust 100%

body
  margin 0
  padding 0
  background-color #fff
  font-weight normal
  font-family: 'HelveticaNeueCyr';
  background url("images/bg1.jpg") center 55px no-repeat

a
  transition all .3s
a.image
  &:hover
    opacity .8
.middle
  width: 940px
  margin: 0 auto

input
  box-shadow: 2px 1px 10px rgba(40, 40, 40, 0.08);
  border-radius: 7px;
  border: 0
  padding: 15px 30px
  background-color: #ffffff;

header
  section.header_nav
    background #282828
    height: 55px
    .right_links
      height 55px
      a
        color: #fff
        height: 45px
        text-decoration unset
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left 20px
        &:hover
          color: #bababa
        &.active
          color: #eed69d
  section.header
    background #f6f5f5
    height: 88px
    input
      padding: 15px 47px 15px 57px
    form
      button
        position absolute
        width: 24px
        margin-top: 10px
        margin-left: 20px
        cursor pointer
        height: 24px
        display: inline-block
        background url("images/icons/search_icon.png")
        border: 0
        &:hover
          opacity .8
    a.logo
      color #dda41b
      text-decoration unset
      span
        font-size: 32px
      span:first-child
        font-weight: bold
      span:last-child
        font-weight: 300
    .header_ad1
      a:hover
        opacity: .8
.main_cats
  height: 55px
  a
    border-top-left-radius 6px
    border-top-right-radius 6px
    background #464545
    color #fff
    font-weight: 300
    text-decoration unset
    padding: 10px 15px
    margin-right 4px
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover
      opacity .8
    &.active
      background: #00c4fd; /* Old browsers */
      background: -moz-linear-gradient(left, #00c4fd 0%, #00fea7 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, #00c4fd 0%,#00fea7 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, #00c4fd 0%,#00fea7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      color black
    i
      margin-right 12px
main.content
  padding: 36px 0 55px 0
  .content_title
    &__inner
      color: #646060;
      font-size: 32px;
      font-weight: 300;
      line-height: 24px
      font-weight: 100
      span:first-child
        font-weight: bold
        color: #000
        margin-right 10px
  .main_cats
    height: auto
    font-size: 16px
    font-weight: normal
    a
      border-radius: 12px 12px 0 0;
      padding: 10px 15px
  .content_videos
    margin-top: 18px
    margin-bottom 50px
    display: flex;
    flex-wrap: wrap;

    &_player
      border-radius 7px
      overflow hidden
      width: 227px
      height: 148px
      margin: 4px
      background-repeat no-repeat
      &_time
        color: #000000;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        position: absolute;
        padding: 0px 8px;
        background: #fff;
        top: 118px
        left: 12px
        border-radius: 8px;
      &_description
        height: 197px
        position relative
        margin-bottom 13px
        i.play_video_min
          position: relative
          z-index: 1
          height: calc(100% - 49px)
          margin-bottom 49px
      &_custom
        height: 180px
        .content_videos_player_footer
          padding-bottom 0
          margin-bottom 0
          margin-top: -32px
        i.play_video_min
          height: calc(100% - 32px);
          margin-bottom: 32px;
      &_footer
        color black
        margin-top: -51px
        padding: 10px
        box-sizing border-box
      &_title
        > span
          font-weight: 500;
          text-transform uppercase
          font-weight: 500;
          text-transform: uppercase;
          overflow: hidden;
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
      &_subtitle
        font-size: 14px
      &_date
        font-size: 12px
        position: absolute
        font-size: 12px;
        position: absolute;
        right: 7px;
        margin-top: -38px;
        span
          padding: 2px 5px;
          margin-left: 5px;
          color: #fff
          border-radius: 8px;
          background-color: #464545;
  .content_categories
    margin-top: 18px
    margin-bottom 50px
    display: flex
    flex-wrap: wrap;
    &_row
      display: flex
      align-items center
      width: 25%
      margin-bottom 12px
      &:hover
        a
          border-bottom: 1px solid #383434;
      span
        border-radius 50%
        display: flex
        align-items center
        justify-content center
        color: #ffffff;
        font-size: 23px;
        font-weight: 500;
        line-height: 24px;
        width: 36px;
        height: 36px;
        background-color: #000000;
        background-image: linear-gradient(to right, #f33ac4 0%, #ff7c07 100%);
        margin-right 15px
      a
        color: #646060;
        text-decoration unset
        font-size: 20px;
        font-weight: 500;
        border-bottom 1px solid transparent
        line-height: 24px;
  .content_links
    margin-top: 18px
    display flex
    flex-wrap: wrap;
    &_row
      width: 33%
      overflow: hidden
    a
        color: #0077dc;
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
        text-decoration unset
        border-bottom 1px solid transparent
        &:hover
          border-bottom: 1px solid #005bb6;
  .content_pagination
    margin-top: 20px
    a.active
      background-color: #464545;
      background-image: linear-gradient(to right, #fdbe00 0%, #e9fe00 100%);
    &_pages
      width: 230px;
      display: flex;
      margin-left: 10px;
      a.active
        background-color: #000000;
        background-image: linear-gradient(to right, #fdbe00 0%, #fed221 100%);
        color: #000
      a
        width: 35px
        height: 35px
        align-items center
        justify-content: center
        display: flex;
        border-radius: 50%
        color: #ffffff;
        font-size: 19px;
        font-weight: 300;
        background-color: #464545;
        margin-right 6px
        text-decoration unset
        line-height: 24px;
        &:hover
          opacity .7
    &_filter
      display: flex
      a.active
        color: #000
      a
        align-items center
        justify-content: center
        display: flex;
        padding: 10px 25px
        text-decoration unset
        background-color: #464545;
        color: white
        &:last-child
          border-top-right-radius: 12px
          border-bottom-right-radius: 12px
        &:first-child
          border-top-left-radius: 12px
          border-bottom-left-radius: 12px
        &:hover
          opacity .7
  .content_tags
    margin-top: 21px
    &_row
      margin-right: 40px
      &:first-child:before
        display: none;
      &:before
        content: '';
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #b2b1b1;
        display: block;
        margin-left: -17px;
        position: absolute;
        margin-top: 6px;
      a
        text-decoration unset
        color black
        text-transform uppercase
        border-bottom: 1px solid transparent
        &:hover
          border-bottom: 1px solid gray
  .content_common_video
    margin-top: 27px
    margin-bottom 48px
    &_player
      width: 617px
      &_body
        height: 400px
        margin-bottom 11px
    &_quality
      width: 100%
      color white
      font-size: 14px
      display: flex
      align-items center
      justify-content: center
      height: 40px;
      border-radius: 0 0 12px 12px;
      background-color: #464545;
      i.hd
        margin-left: 13px
footer
  height: auto
  padding-top: 30px
  background #282828
  p
    margin: 3px 0
  p.copy
    color: #eed69d
  p.desc
    color white
.bnrs
  clear: both;
  display flex
  margin-top: 20px
  margin-bottom: 20px
  align-items center
  justify-content: space-between
  > div
    width: 300px;
    height: 250px;
    background gray
.bnrs2
  clear: both;
  display flex
  margin-top: -45px
  margin-bottom: 20px
  align-items center
  justify-content: space-between
  > div
    width: 300px;
    height: 250px;
    background gray
@media screen and (max-width: 768px)
  .middle
    width: 100%
  footer
    padding-left 10px
  main.content
    padding-top: 20px
    .content_common_video
      display: block;
      .A-flex
        align-items center
        justify-content: center
      &_player
        width: 100%
        margin-bottom 15px
        &_body
          background-size cover
          width: 100%
          height: 300px;
    .content_tags
      display: inline-block
      width: 100%
      padding: 0 10px
      box-sizing border-box
      &_row
        margin-right 25px
        display: inline-block
    .content_pagination
      display: block;
      > *
        margin-left: 0
        padding: 0 10px
        box-sizing border-box
        width: 100%
        margin-bottom: 15px
      .content_pagination_filter
        width: 100%
        a
          padding: 10px 15px
    .content_links
      padding: 0 10px
      &_row
        padding: 0 10px
        width: 100%
    .content_categories
      padding: 0 10px
      &_row
        width: 50%;
        float: left
        a
          font-size: 15px
        span
          min-width 36px
          min-height 36px
    .content_videos_player
      margin: 0 auto
      margin-bottom 15px
      width: 290px;
      height: 189px;
      background-size: contain;
      &_time
        top: 156px
      &_description
        height 242px
    .content_title
      display: block;
      text-align center
      .main_cats
        display: block;
        margin-top: 15px
        a
          border-radius 0
          margin-right 0
      .A-flex
        display: block;

  main.content .content_videos_player_description i.play_video_min {
    position: relative;
    z-index: 1;
    height: calc(100% - 53px);
    margin-bottom: 49px;
  }
  main.content .content_videos_player_date
    margin-top: -41px;
@media screen and (max-width: 1025px)
  main.content

    .content_common_video
      display: block;
      .A-flex
        align-items center
        justify-content: center
      &_player
        width: 100%
        margin-bottom 15px
        &_body
          background-size cover
          width: 100%
          height: 300px;
@media screen and (max-width: 970px)
  .bnrs
  .bnrs2
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    > div
      margin: 5px
      margin-bottom: 20px
  main.content

    .content_common_video
      display: block;
      .A-flex
        align-items center
        justify-content: center
      &_player
        width: 100%
        margin-bottom 15px
        &_body
          background-size cover
          width: 100%
          height: 300px;
    .content_videos_player
      margin: 0 auto
      margin-bottom 15px
      width: 290px;
      height: 189px;
      background-size: contain;
      &_time
        top: 156px
      &_description
        height 242px
    .content_videos_player_custom
      height: 220px
      .content_videos_player_footer
        padding-bottom 0
        margin-bottom 0
        margin-top: -32px
      i.play_video_min
        height: calc(100% - 32px);
        margin-bottom: 32px;
    .content_title
      display: block;
      text-align center
      .main_cats
        display: block;
        margin-top: 15px
        a
          border-radius 0
          margin-right 0
      .A-flex
        display: block;
  header section.mobile_nav
    position absolute
    top: 19px
    right: 18px
    span
      width: 26px;
      height: 3px;
      background: #808080;
      display: block;
      margin-bottom: 2px;
      transition all .3s
    &.active
      span:nth-child(1)
        transform rotate(-45deg)
        position: relative;
        top: 5px;
      span:nth-child(2)
        transform rotate(45deg)
      span:nth-child(3)
        opacity 0
      span:nth-child(4)
        opacity 0
  header section.header_nav
    display: none
    margin-top: 5px
  header section.header_nav.active
    display: block
  header
    .A-flex
      display block
    section.header_nav
      height auto
      .right_links
        height auto
        display: block;
        a
          display inline-block
          margin: 10px 15px
          height:  auto;
    section.header
      display: block;
      height:  auto;
      .header_ad1
        margin-top: 20px!important
        img
          width: 100%
      a.logo
        display inline-block
        margin-bottom 0
      .A-flex > *
        margin: 10px 0;
        width: 100%
        text-align center
    .main_cats
      height auto
      display: block;
      width: 100%
      a
        border-radius 0
        margin-right 0

  .middle
    width: 100%
  .content_categories,
  .content_links,
  main.content .content_title__inner
    padding 0 20px
    box-sizing border-box
@media screen and (min-width: 640px) and (max-width: 1023px)
  html
  body
  header
    width: 100%
  main.content .content_videos_player
    margin: 0 auto;
    margin-bottom: 15px;
    width: 225px;
    height: 152px;
    background-size: contain;
  main.content .content_categories_row
    width: 33%
  main.content .content_links_row
    width: 50%
    box-sizing border-box
  main.content .content_pagination
    display: flex
  main.content .content_videos_player_description
    height: 210px
  main.content .content_videos_player_time
    top: 117px
  .content_common_video .A-flex_column
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
    > a
      margin: 5px

  main.content .content_videos_player_description i.play_video_min
    height: calc(100% - 63px);
    margin-bottom: 49px;
  main.content .content_videos_player_custom
    height: 180px
    .content_videos_player_footer
      padding-bottom 0
      margin-bottom 0
      margin-top: -32px
    i.play_video_min
      height: calc(100% - 32px);
      margin-bottom: 32px;